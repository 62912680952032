import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "usage-without-a-reference-htmlelement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#usage-without-a-reference-htmlelement",
        "aria-label": "usage without a reference htmlelement permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Usage without a reference `}<code parentName="h1" {...{
        "className": "language-text"
      }}>{`HTMLElement`}</code></h1>
    <p>{`Whenever you need to position a popper based on some arbitrary coordinates, you
can provide Popper with a `}<a parentName="p" {...{
        "href": "../../../docs/v2/virtual-elements/"
      }}>{`virtual element`}</a>{`.`}</p>
    <x-ad />
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}><pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` usePopper `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react-popper'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// This is going to create a virtual reference element`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// positioned 10px from top and left of the document`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 90px wide and 10px high`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` virtualReference `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getBoundingClientRect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      top`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      left`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      bottom`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`20`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      right`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      width`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`90`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      height`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`Example`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`popperElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` setPopperElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`useState`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` styles`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` attributes `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`usePopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`virtualReference`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popperElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`ref`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`setPopperElement`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`style`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`styles`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span>{`popper`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token spread"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`...`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}>{`attributes`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}>{`popper`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token plain-text"
          }}>{`
      Popper element
    `}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      